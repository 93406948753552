import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Json: any;
};

export type Campaign = {
  __typename?: "Campaign";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
};

export type CampaignCreateInput = {
  Event?: InputMaybe<EventCreateNestedManyWithoutCampaignInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  filters: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  placement: PlacementCreateNestedOneWithoutCampaignInput;
  variables: Scalars["Json"];
  version: Scalars["Int"];
};

export type CampaignCreateManyPlacementInput = {
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  filters: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  variables: Scalars["Json"];
  version: Scalars["Int"];
};

export type CampaignCreateManyPlacementInputEnvelope = {
  data?: InputMaybe<Array<CampaignCreateManyPlacementInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type CampaignCreateNestedManyWithoutPlacementInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CampaignCreateOrConnectWithoutPlacementInput>
  >;
  create?: InputMaybe<Array<CampaignCreateWithoutPlacementInput>>;
  createMany?: InputMaybe<CampaignCreateManyPlacementInputEnvelope>;
};

export type CampaignCreateNestedOneWithoutEventInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutEventInput>;
  create?: InputMaybe<CampaignCreateWithoutEventInput>;
};

export type CampaignCreateOrConnectWithoutEventInput = {
  create: CampaignCreateWithoutEventInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutPlacementInput = {
  create: CampaignCreateWithoutPlacementInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateWithoutEventInput = {
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  filters: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  placement: PlacementCreateNestedOneWithoutCampaignInput;
  variables: Scalars["Json"];
  version: Scalars["Int"];
};

export type CampaignCreateWithoutPlacementInput = {
  Event?: InputMaybe<EventCreateNestedManyWithoutCampaignInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  filters: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  variables: Scalars["Json"];
  version: Scalars["Int"];
};

export type CampaignIdVersionCompoundUniqueInput = {
  id: Scalars["String"];
  version: Scalars["Int"];
};

export type CampaignWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  id_version?: InputMaybe<CampaignIdVersionCompoundUniqueInput>;
  version?: InputMaybe<Scalars["Int"]>;
};

export enum CostKind {
  Cpm = "CPM",
  Monthly = "Monthly",
  Yearly = "Yearly",
}

export type Event = {
  __typename?: "Event";
  campaignId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  kind: EventKind;
  occurredAt: Scalars["DateTime"];
  placementId?: Maybe<Scalars["String"]>;
  zoneId?: Maybe<Scalars["String"]>;
};

export type EventCount = {
  __typename?: "EventCount";
  count?: Maybe<Scalars["Int"]>;
  key?: Maybe<Scalars["String"]>;
};

export type EventCreateInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutEventInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  placement?: InputMaybe<PlacementCreateNestedOneWithoutEventInput>;
  zone?: InputMaybe<ZoneCreateNestedOneWithoutEventInput>;
};

export type EventCreateManyCampaignInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  placementId?: InputMaybe<Scalars["String"]>;
  zoneId?: InputMaybe<Scalars["String"]>;
};

export type EventCreateManyCampaignInputEnvelope = {
  data?: InputMaybe<Array<EventCreateManyCampaignInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EventCreateManyPlacementInput = {
  campaignId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  zoneId?: InputMaybe<Scalars["String"]>;
};

export type EventCreateManyPlacementInputEnvelope = {
  data?: InputMaybe<Array<EventCreateManyPlacementInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EventCreateManyZoneInput = {
  campaignId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  placementId?: InputMaybe<Scalars["String"]>;
};

export type EventCreateManyZoneInputEnvelope = {
  data?: InputMaybe<Array<EventCreateManyZoneInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type EventCreateNestedManyWithoutCampaignInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<EventCreateManyCampaignInputEnvelope>;
};

export type EventCreateNestedManyWithoutPlacementInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<EventCreateOrConnectWithoutPlacementInput>
  >;
  create?: InputMaybe<Array<EventCreateWithoutPlacementInput>>;
  createMany?: InputMaybe<EventCreateManyPlacementInputEnvelope>;
};

export type EventCreateNestedManyWithoutZoneInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutZoneInput>>;
  create?: InputMaybe<Array<EventCreateWithoutZoneInput>>;
  createMany?: InputMaybe<EventCreateManyZoneInputEnvelope>;
};

export type EventCreateOrConnectWithoutCampaignInput = {
  create: EventCreateWithoutCampaignInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutPlacementInput = {
  create: EventCreateWithoutPlacementInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutZoneInput = {
  create: EventCreateWithoutZoneInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutCampaignInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  placement?: InputMaybe<PlacementCreateNestedOneWithoutEventInput>;
  zone?: InputMaybe<ZoneCreateNestedOneWithoutEventInput>;
};

export type EventCreateWithoutPlacementInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutEventInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  zone?: InputMaybe<ZoneCreateNestedOneWithoutEventInput>;
};

export type EventCreateWithoutZoneInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutEventInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  details: Scalars["Json"];
  id?: InputMaybe<Scalars["String"]>;
  kind?: InputMaybe<EventKind>;
  occurredAt: Scalars["DateTime"];
  placement?: InputMaybe<PlacementCreateNestedOneWithoutEventInput>;
};

export enum EventKind {
  Click = "Click",
  Conversion = "Conversion",
  Error = "Error",
  Impression = "Impression",
}

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createOneCampaign: Campaign;
  createOneEvent: Event;
  createOnePlacement: Placement;
  createOneZone: Zone;
};

export type MutationCreateOneCampaignArgs = {
  data: CampaignCreateInput;
};

export type MutationCreateOneEventArgs = {
  data: EventCreateInput;
};

export type MutationCreateOnePlacementArgs = {
  data: PlacementCreateInput;
};

export type MutationCreateOneZoneArgs = {
  data: ZoneCreateInput;
};

export type Placement = {
  __typename?: "Placement";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  location: Scalars["String"];
  name: Scalars["String"];
  path: Scalars["String"];
  status: PlacementStatus;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version: Scalars["Int"];
  zoneId: Scalars["String"];
};

export type PlacementCreateInput = {
  Campaign?: InputMaybe<CampaignCreateNestedManyWithoutPlacementInput>;
  Event?: InputMaybe<EventCreateNestedManyWithoutPlacementInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costKind?: InputMaybe<CostKind>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  path: Scalars["String"];
  status?: InputMaybe<PlacementStatus>;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version?: InputMaybe<Scalars["Int"]>;
  zone: ZoneCreateNestedOneWithoutPlacementInput;
};

export type PlacementCreateManyZoneInput = {
  changelog?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costKind?: InputMaybe<CostKind>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  path: Scalars["String"];
  status?: InputMaybe<PlacementStatus>;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type PlacementCreateManyZoneInputEnvelope = {
  data?: InputMaybe<Array<PlacementCreateManyZoneInput>>;
  skipDuplicates?: InputMaybe<Scalars["Boolean"]>;
};

export type PlacementCreateNestedManyWithoutZoneInput = {
  connect?: InputMaybe<Array<PlacementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlacementCreateOrConnectWithoutZoneInput>>;
  create?: InputMaybe<Array<PlacementCreateWithoutZoneInput>>;
  createMany?: InputMaybe<PlacementCreateManyZoneInputEnvelope>;
};

export type PlacementCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<PlacementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlacementCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<PlacementCreateWithoutCampaignInput>;
};

export type PlacementCreateNestedOneWithoutEventInput = {
  connect?: InputMaybe<PlacementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlacementCreateOrConnectWithoutEventInput>;
  create?: InputMaybe<PlacementCreateWithoutEventInput>;
};

export type PlacementCreateOrConnectWithoutCampaignInput = {
  create: PlacementCreateWithoutCampaignInput;
  where: PlacementWhereUniqueInput;
};

export type PlacementCreateOrConnectWithoutEventInput = {
  create: PlacementCreateWithoutEventInput;
  where: PlacementWhereUniqueInput;
};

export type PlacementCreateOrConnectWithoutZoneInput = {
  create: PlacementCreateWithoutZoneInput;
  where: PlacementWhereUniqueInput;
};

export type PlacementCreateWithoutCampaignInput = {
  Event?: InputMaybe<EventCreateNestedManyWithoutPlacementInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costKind?: InputMaybe<CostKind>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  path: Scalars["String"];
  status?: InputMaybe<PlacementStatus>;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version?: InputMaybe<Scalars["Int"]>;
  zone: ZoneCreateNestedOneWithoutPlacementInput;
};

export type PlacementCreateWithoutEventInput = {
  Campaign?: InputMaybe<CampaignCreateNestedManyWithoutPlacementInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costKind?: InputMaybe<CostKind>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  path: Scalars["String"];
  status?: InputMaybe<PlacementStatus>;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version?: InputMaybe<Scalars["Int"]>;
  zone: ZoneCreateNestedOneWithoutPlacementInput;
};

export type PlacementCreateWithoutZoneInput = {
  Campaign?: InputMaybe<CampaignCreateNestedManyWithoutPlacementInput>;
  Event?: InputMaybe<EventCreateNestedManyWithoutPlacementInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Int"]>;
  costKind?: InputMaybe<CostKind>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  owner: Scalars["String"];
  path: Scalars["String"];
  status?: InputMaybe<PlacementStatus>;
  template: Scalars["String"];
  variables: Scalars["Json"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type PlacementIdVersionCompoundUniqueInput = {
  id: Scalars["String"];
  version: Scalars["Int"];
};

export enum PlacementStatus {
  Active = "Active",
  Archived = "Archived",
  Draft = "Draft",
  Paused = "Paused",
}

export type PlacementWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  id_version?: InputMaybe<PlacementIdVersionCompoundUniqueInput>;
};

export type Query = {
  __typename?: "Query";
  currentUser?: Maybe<User>;
  zone?: Maybe<Zone>;
};

export type QueryZoneArgs = {
  where: ZoneWhereUniqueInput;
};

export type User = {
  __typename?: "User";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  picture?: Maybe<Scalars["String"]>;
  sub?: Maybe<Scalars["String"]>;
  /** @deprecated Use "sub" instead */
  user_id?: Maybe<Scalars["String"]>;
  zones?: Maybe<Array<Maybe<Zone>>>;
};

export type Zone = {
  __typename?: "Zone";
  changelog: Scalars["String"];
  createdAt: Scalars["DateTime"];
  eventCounts?: Maybe<Array<Maybe<EventCount>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  id: Scalars["String"];
  members: Array<Scalars["String"]>;
  origins: Array<Scalars["String"]>;
  owner: Scalars["String"];
  placements?: Maybe<Array<Maybe<Placement>>>;
  version: Scalars["Int"];
};

export type ZoneEventCountsArgs = {
  interval?: InputMaybe<Scalars["String"]>;
};

export type ZoneCreateInput = {
  Event?: InputMaybe<EventCreateNestedManyWithoutZoneInput>;
  Placement?: InputMaybe<PlacementCreateNestedManyWithoutZoneInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  members?: InputMaybe<ZoneCreatemembersInput>;
  origins?: InputMaybe<ZoneCreateoriginsInput>;
  owner: Scalars["String"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type ZoneCreateNestedOneWithoutEventInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ZoneCreateOrConnectWithoutEventInput>;
  create?: InputMaybe<ZoneCreateWithoutEventInput>;
};

export type ZoneCreateNestedOneWithoutPlacementInput = {
  connect?: InputMaybe<ZoneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ZoneCreateOrConnectWithoutPlacementInput>;
  create?: InputMaybe<ZoneCreateWithoutPlacementInput>;
};

export type ZoneCreateOrConnectWithoutEventInput = {
  create: ZoneCreateWithoutEventInput;
  where: ZoneWhereUniqueInput;
};

export type ZoneCreateOrConnectWithoutPlacementInput = {
  create: ZoneCreateWithoutPlacementInput;
  where: ZoneWhereUniqueInput;
};

export type ZoneCreateWithoutEventInput = {
  Placement?: InputMaybe<PlacementCreateNestedManyWithoutZoneInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  members?: InputMaybe<ZoneCreatemembersInput>;
  origins?: InputMaybe<ZoneCreateoriginsInput>;
  owner: Scalars["String"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type ZoneCreateWithoutPlacementInput = {
  Event?: InputMaybe<EventCreateNestedManyWithoutZoneInput>;
  changelog?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  id?: InputMaybe<Scalars["String"]>;
  members?: InputMaybe<ZoneCreatemembersInput>;
  origins?: InputMaybe<ZoneCreateoriginsInput>;
  owner: Scalars["String"];
  version?: InputMaybe<Scalars["Int"]>;
};

export type ZoneCreatemembersInput = {
  set?: InputMaybe<Array<Scalars["String"]>>;
};

export type ZoneCreateoriginsInput = {
  set?: InputMaybe<Array<Scalars["String"]>>;
};

export type ZoneIdVersionCompoundUniqueInput = {
  id: Scalars["String"];
  version: Scalars["Int"];
};

export type ZoneWhereUniqueInput = {
  id?: InputMaybe<Scalars["String"]>;
  id_version?: InputMaybe<ZoneIdVersionCompoundUniqueInput>;
};

export type CreateCampaignMutationVariables = Exact<{
  data: CampaignCreateInput;
}>;

export type CreateCampaignMutation = {
  __typename?: "Mutation";
  createOneCampaign: { __typename?: "Campaign"; id: string };
};

export type CreateEventMutationVariables = Exact<{
  data: EventCreateInput;
}>;

export type CreateEventMutation = {
  __typename?: "Mutation";
  createOneEvent: { __typename?: "Event"; id: string };
};

export type CreatePlacementMutationVariables = Exact<{
  data: PlacementCreateInput;
}>;

export type CreatePlacementMutation = {
  __typename?: "Mutation";
  createOnePlacement: { __typename?: "Placement"; id: string };
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    sub?: string | null;
    nickname?: string | null;
    name?: string | null;
    email?: string | null;
    picture?: string | null;
    zones?: Array<{
      __typename?: "Zone";
      id: string;
      version: number;
      origins: Array<string>;
      placements?: Array<{
        __typename?: "Placement";
        id: string;
        version: number;
        status: PlacementStatus;
        name: string;
        location: string;
        path: string;
        template: string;
        variables: any;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ZoneQueryVariables = Exact<{
  where: ZoneWhereUniqueInput;
  interval?: InputMaybe<Scalars["String"]>;
}>;

export type ZoneQuery = {
  __typename?: "Query";
  zone?: {
    __typename?: "Zone";
    id: string;
    version: number;
    origins: Array<string>;
    members: Array<string>;
    owner: string;
    changelog: string;
    createdAt: any;
    placements?: Array<{
      __typename?: "Placement";
      id: string;
      version: number;
      status: PlacementStatus;
      location: string;
      path: string;
      template: string;
      variables: any;
    } | null> | null;
    eventCounts?: Array<{
      __typename?: "EventCount";
      count?: number | null;
      key?: string | null;
    } | null> | null;
  } | null;
};

export const CreateCampaignDocument = gql`
  mutation CreateCampaign($data: CampaignCreateInput!) {
    createOneCampaign(data: $data) {
      id
    }
  }
`;
export const CreateEventDocument = gql`
  mutation CreateEvent($data: EventCreateInput!) {
    createOneEvent(data: $data) {
      id
    }
  }
`;
export const CreatePlacementDocument = gql`
  mutation CreatePlacement($data: PlacementCreateInput!) {
    createOnePlacement(data: $data) {
      id
    }
  }
`;
export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser {
      sub
      nickname
      name
      email
      picture
      zones {
        id
        version
        origins
        placements {
          id
          version
          status
          name
          location
          path
          template
          variables
        }
      }
    }
  }
`;
export const ZoneDocument = gql`
  query Zone($where: ZoneWhereUniqueInput!, $interval: String) {
    zone(where: $where) {
      id
      version
      origins
      members
      owner
      changelog
      createdAt
      placements {
        id
        version
        status
        location
        path
        template
        variables
      }
      eventCounts(interval: $interval) {
        count
        key
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    CreateCampaign(
      variables: CreateCampaignMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateCampaignMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateCampaignMutation>(
            CreateCampaignDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "CreateCampaign",
        "mutation"
      );
    },
    CreateEvent(
      variables: CreateEventMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreateEventMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateEventMutation>(CreateEventDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CreateEvent",
        "mutation"
      );
    },
    CreatePlacement(
      variables: CreatePlacementMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CreatePlacementMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreatePlacementMutation>(
            CreatePlacementDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "CreatePlacement",
        "mutation"
      );
    },
    CurrentUser(
      variables?: CurrentUserQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CurrentUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CurrentUserQuery>(CurrentUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "CurrentUser",
        "query"
      );
    },
    Zone(
      variables: ZoneQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ZoneQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ZoneQuery>(ZoneDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "Zone",
        "query"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
