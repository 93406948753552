var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry";
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";
import * as Tracing from "@sentry/tracing";

declare let VERSION: string;

const integrations = [];

integrations.push(
  new Tracing.Integrations.BrowserTracing({
    tracingOrigins: [
      process.env.NEXT_PUBLIC_ELSPONSOR_URL
        ? new URL(process.env.NEXT_PUBLIC_ELSPONSOR_URL).host
        : "*.elsponsor.com",
    ],
  })
);

Sentry.init({
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV,
  dsn: "https://cffd4460086a472fa13f94afb6f74394@o2125.ingest.sentry.io/4504390970245120",
  release: VERSION,
  tracesSampleRate: 1.0,
  integrations,
});
